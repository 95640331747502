import { assignIn } from "lodash";

export const collectUrlSearch = (searchParams, inboxState, user) => {
  const uSearchParams = new URLSearchParams(searchParams);
  if (uSearchParams.has("user_inbox")) {
    inboxState.setUser(uSearchParams.get("user_inbox"));
  } else {
    inboxState.setUser(user.email);
  }
  if (uSearchParams.has("reason")) {
    inboxState.setReason(uSearchParams.get("reason"));
  } else {
    inboxState.setReason("");
  }
};

export const buildUrlSearch = (token, client, inboxState) => {
  let params = {
    access_token: token,
    client: client,
    inbox: true,
    limit: inboxState.size,
    offset: inboxState.offset,
    order: inboxState.order,
    order_by: inboxState.orderedBy,
    user_inbox: inboxState.user,
  };
  if (inboxState.reason !== "") {
    params = assignIn(params, {
      reason: inboxState.reason,
    });
  }
  return params;
};
