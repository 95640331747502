import { asyncify, memoize } from "async";
import axios from "axios";
import clsx from "clsx";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { assignIn, capitalize, debounce, isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import objectHash from "object-hash";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Parcel from "single-spa-react/parcel";

import { collectUrlSearch, buildUrlSearch } from "../helpers";

import config from "../../../../config";
import { inboxReasons } from "../../../../const";
import useAuthentication from "../../../../hooks/useAuthentication";
import usePrevious from "../../../../hooks/usePrevious";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

import "../scss/App.scoped.scss";

import InboxSearch from "./InboxSearch";
import { OrganizationsParcel } from "../../../dig/src/InReachVentures-dig.organizations";
import InboxPagination from "./InboxPagination";

dayjs.extend(customParseFormat);

const debounceWithMemo = (fn, timeout) => {
  let debounced = debounce(fn, timeout);
  debounced.memo = fn.memo;
  return debounced;
};

const hasherParams = (params) => {
  params = new URLSearchParams(params);
  params.delete("access_token");
  params.delete("client");
  const hash = Object.fromEntries(params.entries());
  return objectHash(hash);
};

const hasherURL = (url) => {
  const params = new URLSearchParams(url.search);
  params.delete("access_token");
  params.delete("client");
  const hash = Object.fromEntries(params.entries());
  return objectHash(hash);
};

const axiosGetDebouncedMemoized = debounceWithMemo(
  memoize(
    asyncify(async (url, options) => {
      const result = await axios.get(url, options);
      return result;
    }),
    hasherURL
  ),
  200
);

function App(props) {
  const { inboxState, mountParcel, userState } = props;

  const location = useLocation();
  const history = useHistory();

  const [mounted, setMounted] = useState(false);
  const [navVisible, setNavVisible] = useState(true);
  const [structuredFeedbackTypes, setStructuredFeedbackTypes] = useState({});
  const [organizationSyncsOpen, setOrganizationSyncsOpen] = useState({});

  const topEl = useRef(null);
  const previousOffset = usePrevious(inboxState.offset);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    window.addEventListener(
      "nav_visible",
      (e) => {
        setNavVisible(e.detail);
      },
      false
    );
  }, []);

  const organizationsApiUrl = new URL(config.api.host + "/organizations");
  const inboxesApiUrl = new URL(config.api.host + "/inbox/reasons");

  const scrollToHash = () => {
    if (!document.querySelector(location.hash)) {
      window.requestAnimationFrame(scrollToHash);
    } else {
      window.scrollTo(0, document.querySelector(location.hash).offsetTop);
    }
  };

  const scrollToTop = () => {
    if (topEl?.current) {
      while (topEl.current.scrollHeight === 0) {
        window.requestAnimationFrame(scrollToTop);
      }
      window.scrollTo(0, topEl.current.offsetTop);
    }
  };

  const updateSearch = (params) => {
    delete params.access_token;
    delete params.client;
    history.replace(`${location.pathname}?${new URLSearchParams(params)}`, {});
  };

  const collectOrganizations = (params) => {
    organizationsApiUrl.search = new URLSearchParams(params).toString();
    updateSearch(params);
    axiosGetDebouncedMemoized(
      organizationsApiUrl,
      {
        timeout: 10000,
        headers: {
          Authorization: "Bearer " + userState.token,
        },
      },
      (e, response) => {
        if (e !== null) {
          inboxState.setApiStatus(e?.response?.status || "timeout");
          inboxState.setTotal(0);
          console.error(e.response);
        } else {
          inboxState.setApiStatus(200);
          inboxState.setOrganizations(response.data.organizations || []);
          inboxState.setTotal(response.data.total);
        }
      }
    );
  };

  const retryCollectOrganizations = () => {
    inboxState.setApiStatus(200);
    const params = buildUrlSearch(
      userState.token,
      userState.client,
      inboxState
    );
    organizationsTrigger(params);
  };

  const collectNextOrganization = async () => {
    let searchParams = buildUrlSearch(
      userState.token,
      userState.client,
      inboxState
    );
    searchParams = assignIn(searchParams, {
      offset: inboxState.offset + inboxState.size,
      limit: 1,
    });
    const url = new URL(organizationsApiUrl);
    url.search = new URLSearchParams(searchParams).toString();
    const organization = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + userState.token,
      },
    });
    const organizationData = organization.data.organizations[0];
    inboxState.addOrganization(organizationData.id, organizationData);
  };

  const handleOrganizationChange = async (
    id,
    operation = "update",
    reason = undefined
  ) => {
    const searchParams = new URLSearchParams({
      access_token: userState.token,
      client: userState.client,
    });
    const url = new URL(config.api.host + "/organizations" + `/${id}`);
    url.search = searchParams.toString();
    const organization = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + userState.token,
      },
    });
    const params = buildUrlSearch(
      userState.token,
      userState.client,
      inboxState
    );
    const hash = hasherParams(params);
    delete axiosGetDebouncedMemoized.memo[hash];
    switch (operation) {
      case "add":
        inboxState.addOrganization(id, organization.data);
        inboxState.incrementCounter("inbox");
        break;
      case "remove":
        inboxState.removeOrganization(id, organization.data);
        if (inboxState.total > inboxState.size) collectNextOrganization();
        inboxState.decreaseCounter("inbox");
        if (reason !== undefined) inboxState.decreaseCounter(reason);
        break;
      case "update":
      default:
        inboxState.updateOrganization(id, organization.data);
        break;
    }
  };

  const flattenArrayParams = (params = {}) =>
    Object.entries(params).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: Array.isArray(v) ? v.join(",") : v,
      }),
      {}
    );

  if (!mounted && location.search) {
    collectUrlSearch(location.search, inboxState, userState.user);
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  const organizationsTrigger = (params) => {
    collectOrganizations(params);
    if (location.hash) {
      scrollToHash();
    }
    if (previousOffset !== inboxState.offset && topEl.current) {
      scrollToTop();
    }
  };

  const inboxesTrigger = async () => {
    inboxesApiUrl.search = new URLSearchParams({
      access_token: userState.token,
      assigned_to: inboxState.user,
      client: userState.client,
    }).toString();
    const options = {
      headers: {
        Authorization: "Bearer " + userState.token,
      },
    };
    const counts = await axios.get(inboxesApiUrl, options);
    inboxState.setCounters(counts.data.reasons);
  };

  useEffect(() => {
    if (userState.token && userState.client) {
      const params = buildUrlSearch(
        userState.token,
        userState.client,
        inboxState
      );
      organizationsTrigger(params);
      if (inboxState.firstRun && !location.search) {
        inboxState.setUser(userState.user.email);
      }
      if (inboxState.firstRun) {
        inboxState.setFirstRun(false);
      }
    }
  }, [
    inboxState.offset,
    inboxState.orderedBy,
    inboxState.order,
    inboxState.user,
    inboxState.reason,
    userState.client,
    userState.token,
    userState.user,
  ]);

  useEffect(() => {
    if (userState.token && userState.client && inboxState.forceUpdate) {
      const params = buildUrlSearch(
        userState.token,
        userState.client,
        inboxState
      );
      const hash = hasherParams(params);
      delete axiosGetDebouncedMemoized.memo[hash];
      organizationsTrigger(params);
      inboxState.setForceUpdate(false);
    }
  }, [inboxState.forceUpdate]);

  useEffect(() => {
    if (
      inboxState.user &&
      userState.client &&
      userState.token &&
      userState.user
    ) {
      inboxesTrigger();
    }
  }, [inboxState.user, userState.client, userState.token, userState.user]);

  useEffect(() => {
    if (inboxState.counters && userState.inboxes) {
      const inboxes = userState.inboxes.length
        ? new Set([
            ...inboxState.countersKeys,
            ...Object.values(userState.inboxes),
          ])
        : inboxReasons
            .filter((inbox) => inbox.reason !== "inbox")
            .map((inbox) => inbox.filters)
            .flat();

      let aggregatedInboxes = Array.from(inboxes)
        .reduce((acc, inbox) => {
          acc.push([
            inboxReasons.find(
              (reason) =>
                reason.reason === inbox || reason.filters.includes(inbox)
            ) || {
              title: capitalize(inbox),
              filters: [inbox],
              icon: "bi-envelope",
            },
            inbox,
          ]);
          return acc;
        }, [])
        .reduce((acc, [reason, inbox], index) => {
          const existingReason = acc.find(([r, i]) =>
            isEqual(r.filters, reason.filters)
          );
          if (existingReason) existingReason[1].push(inbox);
          else acc.push([reason, [inbox]]);
          return acc;
        }, [])
        .map(([reason, inboxes]) => {
          return {
            ...reason,
            count: inboxes.reduce(
              (acc, i) => acc + (inboxState.counters[i]?.count || 0),
              0
            ),
          };
        })
        .sort((a, b) => a.title.localeCompare(b.title));

      inboxState.setAggregatedInboxes(aggregatedInboxes);
    }
  }, [inboxState.counters, userState.inboxes]);

  function handleOpenStructuredFeedback(type, organizationId) {
    const newTypes = Object.assign({}, structuredFeedbackTypes);
    newTypes[organizationId] = type;
    setStructuredFeedbackTypes(newTypes);
  }

  function handleCloseStructuredFeedback() {
    setStructuredFeedbackTypes({});
  }

  function handleOpenWorkflowSync(organizationId) {
    const newOpens = Object.assign({}, organizationSyncsOpen);
    newOpens[organizationId] = true;
    setOrganizationSyncsOpen(newOpens);
  }

  function handleCloseWorkflowSync() {
    setOrganizationSyncsOpen({});
  }

  const searchParams = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  );

  return (
    <div class="row">
      <div class="col-lg-4">
        <div
          class={clsx(
            "organization-search-container",
            !navVisible && width > 992 && "stuck",
            width < 992 && "mb-3"
          )}
        >
          <InboxSearch
            inboxState={inboxState}
            navVisible={navVisible}
            userState={userState}
          />
        </div>
      </div>
      <div class="col-lg-8" ref={topEl}>
        <Parcel
          config={OrganizationsParcel}
          mountParcel={mountParcel}
          handleError={(err) => console.error(err)}
          apiStatus={inboxState.apiStatus}
          handleOrganizationChange={handleOrganizationChange}
          organizations={inboxState.organizationsArray}
          pathname={location.pathname}
          searchParams={searchParams}
          filterSource={{
            route: location.pathname.replace("/", ""),
            params: flattenArrayParams(searchParams),
          }}
          userHasFullAccess={userState.accessType === "full"}
          userRoles={userState.roles}
          token={userState.token}
          user={userState.user}
          client={userState.client}
          retryCollectOrganizations={retryCollectOrganizations}
          source="inbox"
          openStructuredFeedback={handleOpenStructuredFeedback}
          closeStructuredFeedback={handleCloseStructuredFeedback}
          structuredFeedbackTypes={structuredFeedbackTypes}
          openWorkflowSync={handleOpenWorkflowSync}
          closeWorkflowSync={handleCloseWorkflowSync}
          organizationSyncsOpen={organizationSyncsOpen}
        />
        <InboxPagination inboxState={inboxState} />
      </div>
    </div>
  );
}

export default observer(App);
