import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";

import "../scss/InboxPagination.scoped.scss";

function InboxPagination(props) {
  const { inboxState } = props;

  const moveToPage = (i) => {
    const offset = i * inboxState.size;
    inboxState.setOrganizations(null);
    inboxState.setOffset(offset);
  };

  const moveNext = () => {
    if (!inboxState.nextDisabled) {
      const nextOffset = inboxState.offset + inboxState.size;
      inboxState.setOrganizations(null);
      inboxState.setOffset(nextOffset);
    }
  };

  const movePrevious = (i) => {
    if (!inboxState.previousDisabled) {
      const previousOffset = inboxState.offset - inboxState.size;
      inboxState.setOrganizations(null);
      inboxState.setOffset(previousOffset);
    }
  };

  const moveFirst = () => {
    const nextOffset = 0;
    inboxState.setOrganizations(null);
    inboxState.setOffset(nextOffset);
  };

  const moveLast = () => {
    const nextOffset = inboxState.size * inboxState.pagesNumber;
    inboxState.setOrganizations(null);
    inboxState.setOffset(nextOffset);
  };

  if (
    (inboxState.previousDisabled && inboxState.nextDisabled) ||
    inboxState.organizations === null
  ) {
    return;
  }

  return (
    <nav>
      <ul class="pagination justify-content-center">
        <li
          className={clsx(
            "page-item",
            inboxState.previousDisabled && "disabled"
          )}
        >
          <button
            class="page-link"
            onClick={(event) => {
              moveFirst();
              event.preventDefault();
            }}
          >
            First
          </button>
        </li>
        <li
          className={clsx(
            "page-item",
            inboxState.previousDisabled && "disabled"
          )}
        >
          <button
            class="page-link"
            onClick={(event) => {
              movePrevious();
              event.preventDefault();
            }}
          >
            Previous
          </button>
        </li>
        {[...Array(inboxState.pagesNumber + 1).keys()]
          .slice(inboxState.bottomPage, inboxState.topPage)
          .map((i) => {
            return (
              <li
                className={clsx(
                  "page-item",
                  i === inboxState.currentPage && "active disabled"
                )}
              >
                <button
                  class="page-link"
                  onClick={(event) => {
                    moveToPage(i);
                    event.preventDefault();
                  }}
                >
                  {i + 1}
                </button>
              </li>
            );
          })}
        <li
          className={clsx("page-item", inboxState.nextDisabled && "disabled")}
        >
          <button
            class="page-link"
            onClick={(event) => {
              moveNext();
              event.preventDefault();
            }}
          >
            Next
          </button>
        </li>
        <li
          className={clsx("page-item", inboxState.nextDisabled && "disabled")}
        >
          <button
            class="page-link"
            onClick={(event) => {
              moveLast();
              event.preventDefault();
            }}
          >
            Last
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default observer(InboxPagination);
