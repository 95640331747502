import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import AppGuard from "../../../components/AppGuard";
import App from "./components/App";

const theme = createMuiTheme({
  palette: {
    primary: {
      //main: "#5dab49",
      main: "#ffffff",
    },
    secondary: {
      //main: "#337ab7",
      main: "#5dab49",
    },
    success: {
      main: "#52ab76",
    },
    danger: {
      main: "#ab4038",
    },
  },
});

export default function Root(props) {
  const { auth0, inboxState, mountParcel, userState } = props;

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/inbox"
            render={() => (
              <AppGuard
                app={
                  <App
                    auth0={auth0}
                    inboxState={inboxState}
                    mountParcel={mountParcel}
                    userState={userState}
                  />
                }
                auth0={auth0}
                freeEntry={false}
                userState={userState}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}
