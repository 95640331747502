import axios from "axios";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "../scss/InboxSearch.scoped.scss";

import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useCollapse, STATE } from "../../../../hooks/useTransitionControl";

import { collectUrlSearch } from "../helpers";
import config from "../../../../config";
import users from "../../../../components/js/config/inreach_team";

const activeUsers = users.filter((user) => !user.exclude);

function InboxSearch(props) {
  const { inboxState, navVisible, userState } = props;
  const { width, height } = useWindowDimensions();
  const [state, toggle, enter] = useCollapse(
    350,
    STATE.ENTERED,
    "#organization-search-collapsable"
  );
  const location = useLocation();
  const [counterInbox, setCounterInbox] = useState(inboxState.counterInbox);

  useEffect(
    debounce(() => {
      if (width >= 991 && state === STATE.EXITED) {
        enter();
      }
    }, 500),
    [width]
  );

  useEffect(() => {
    if (!location.search && location.state?.from === location.pathname) {
      inboxState.setOrganizations(null);
      inboxState.setOffset(0);
      inboxState.setUser(userState.user.email);
      inboxState.setReason("");
    }
  }, [location]);

  const collectInboxNumber = async () => {
    const searchParams = new URLSearchParams({
      access_token: userState.token,
      client: userState.client,
      user_inbox: inboxState.user,
      inbox: true,
      limit: 0,
    });
    const url = new URL(config.api.host + "/organizations");
    url.search = searchParams.toString();
    const organizations = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + userState.token,
      },
    });
    const inboxItemsNumber = organizations.data.total;
    setCounterInbox(inboxItemsNumber);
  };

  useEffect(() => {
    if (userState.client && userState.token) {
      if (inboxState.user !== userState.user.email) collectInboxNumber();
      else setCounterInbox(inboxState.counterInbox);
    }
  }, [
    inboxState.counterInbox,
    inboxState.organizations,
    inboxState.user,
    userState.client,
    userState.token,
  ]);

  return (
    <div
      className={clsx(
        "organization-search-fill-height",
        navVisible && width > 992 && "unstuck"
      )}
      style={{
        height:
          width > 992
            ? navVisible
              ? `calc(100vh - ${
                  document.getElementById("nav").offsetHeight + 15
                }px)`
              : "auto"
            : "auto",
      }}
    >
      <div
        className={clsx(
          "border",
          "rounded-bottom",
          "p-3",
          "organization-search-filter-container",
          !navVisible && width > 992 && "stuck"
        )}
        //style={{ height: organizationsSearchFilterContainerHeight}}
        id="organization-search-collapsable"
      >
        <div class="input-group input-group-sm mb-3">
          <label for="userSelect" class="input-group-text">
            User
          </label>
          <select
            class="form-select form-select-sm"
            id="userSelect"
            onChange={(event) => {
              inboxState.setOrganizations(null);
              inboxState.setOffset(0);
              inboxState.setUser(event.target.value);
              inboxState.setReason("");
            }}
          >
            {activeUsers
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((activeUser) => (
                <option
                  selected={inboxState.user === activeUser.email}
                  value={activeUser.email}
                >
                  {activeUser.name}
                </option>
              ))}
          </select>
        </div>
        <div class="list-group" id="list-tab" role="tablist">
          <button
            className={clsx(
              "btn btn-link list-group-item list-group-item-action d-flex align-items-center mb-1",
              inboxState.reason === "" && "active"
            )}
            data-bs-toggle="list"
            href="#"
            role="tab"
            aria-controls="list-home"
            onClick={(event) => {
              event.preventDefault();
              if (inboxState.reason !== "") {
                inboxState.setOrganizations(null);
                inboxState.setOffset(0);
                inboxState.setReason("");
              }
            }}
          >
            <i class="bi bi-inboxes me-2"></i>
            <span class="flex-fill">Inbox</span>
            <span class="badge bg-primary rounded-pill">{counterInbox}</span>
          </button>
          {inboxState.aggregatedInboxes.length > 0 &&
            inboxState.aggregatedInboxes.map((inbox) => {
              return (
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    if (inboxState.reason !== inbox.filters.join(",")) {
                      inboxState.setOrganizations(null);
                      inboxState.setOffset(0);
                      inboxState.setReason(inbox.filters.join(","));
                    }
                  }}
                  className={clsx(
                    "btn btn-link mb-1 list-group-item list-group-item-action d-flex align-items-center",
                    inboxState.reason === inbox.filters.join(",") && "active"
                  )}
                  data-bs-toggle="list"
                  role="tab"
                  aria-controls="list-home"
                >
                  <i className={clsx("bi me-2", inbox.icon)}></i>
                  <span class="flex-fill">{inbox.title}</span>
                  {inbox.count > 0 && (
                    <span class="badge bg-primary rounded-pill">
                      {inbox.count}
                    </span>
                  )}
                </button>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default observer(InboxSearch);
